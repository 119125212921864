<style lang="scss" scoped>
  .tolttip-message{
    display:none !important;
  }
</style>
<style lang="scss" scoped>

  .c-main{
    overflow: hidden;
  }

  .container__message {
    margin: 0 auto;
    width: 570px;
    position: relative;
    height: 60vh;
    background: transparent;
  }

  .circle__style.informativa {
    width: 18.75em;
    height: 16.87em;
    background: #4b92fc;
    border-radius: 100%;
    padding: 36px 40px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: relative;

    span {
      font-size: 34px;
      font-weight: bold;
    }
    p {
      line-height: 22px;
      color: #fff;
    }
  }

  .informativa .triangulo {
    width: 0;
    height: 0;
    border-left: 48px solid #4b92fc;
    border-top: 12px solid transparent;
    border-bottom: 47px solid transparent;
    position: absolute;
    bottom: 6px;
    right: 3px;
    -webkit-transform: rotate(49deg);
    transform: rotate(49deg);
    z-index: -1;
  }

.avatar__alicia__message {
  z-index: -2;
  position: absolute;
  width: 35vw;
  height: 65vh;
  bottom: 0;
  top:10vh;
  right: 0px;
  left: 10.6em;
}

.card {
  margin-bottom: 0px !important;
}

  .text-content-modal{
    font-size: 1em;
  }

  .avatar__alicia__mobile{
    display: none;
  }

@media only screen and (max-width: 590px) {
  .title__section{
    font-size: 0.8em;
  }
  .circle__style.informativa {
    margin-top: 8%;
    width: 13em;
    height: 11em;
    padding: 1.2em 1.2em;
  }


.btn__modal{
    margin-top: 1em !important;
}

  .circle__style.informativa p{
    font-size: 0.8em;
  }

  .circle__style.informativa span{
    font-size: 1.2em;
  }

  .informativa .triangulo {
    bottom: -32px;
    right: 50px;
    -webkit-transform: rotate(76deg);
    transform: rotate(76deg);
  }
  
  .container__message {
    width: 18.75em !important;
    padding-bottom:1em !important;
  }

  .avatar__alicia__message {
    width: 190px !important;
    display:none !important;
  }

  .modal.show .modal-dialog {
    max-width: 80%!important;
  }

  .text-content-modal{
    font-size: 0.8em;
    text-align: justify;
  }
  .avatar__alicia__mobile{
    left: 130px;
    top:20px !important;
    display: block;
  }

}
</style>

<style lang="scss" scoped>

.btn__modal{
    background: transparent;
    color: #fff;
    padding: 5px 10px;
    margin-top: 2em;
    cursor: pointer;
    border: 1px  solid;
}
.btn__modal:hover{
    background: #000;
    color: #fff;
}
</style>

<template>
  <CRow>
    <loading :active.sync="isLoading" 
    :can-cancel="false" 
    :is-full-page="fullPage"></loading>
    <CCol col>
      <CCard>
        <CCardHeader>
          <div style="margin-top:10px;">
            <strong class="title__section">¿Quieres acceder a los beneficios del Club El Comercio?</strong>
          </div>          
          </CCardHeader>
        <CCardBody>
          <CCard class="mb-0">            
            <CCardBody>          
              <div class="container__message">
                <div class="circle__style informativa">
                  <span>{{firstName.toUpperCase()}}</span>
                  <p>
                    Si lo que quieres es conocer los beneficios del Club El Comercio
                    <br>
                    <button class="btn__modal" @click="$bvModal.show('modal-xl') + hideImg()">Clic Aquí</button>
                  </p>
                  <div class="triangulo"></div>
                </div>
                <img v-if="flagImg==1" class="avatar__alicia__message" src="../../assets/img/alicia_baner.png" />
                <img class="avatar__alicia__mobile" src="../../assets/img/alicia_3.jpg" />
              </div>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
    </CCol>

  <b-modal id="modal-xl" size="xl" hide-footer no-close-on-backdrop hide-header-close no-close-on-esc>
    <template #modal-title>
      Beneficios del Club El Comercio
    </template>
    <div class="d-block text-center">
      <div class="text-left">
        <p class="text-content-modal">
          <ol>
            <li><b>Promociones</b>
                <br>¡Tenemos las mejores promociones! Estos son nuestros últimos ingresos:
                <br>-30% de dscto en Segundo Muelle hasta el 30 de junio del 2021.
                <br>-20% de dscto en la prueba rápida (ANTICUERPOS IgG/IgM) AUNA, hasta 23/05/2021.
                <br>-30% de dscto en Montalvo de lunes a jueves, hasta 31/03/2021. 
                <br><br>Para enterarte de todas las promociones y conocer sus términos y condiciones legales, ingresa a <a href="https://clubelcomercio.pe" target="_blank">https://clubelcomercio.pe</a> o descarga el App Club El Comercio en play store o app store.</li>
              <br>
            <li><b>Como usar mis beneficios</b>
              <br>¡Es muy sencillo! Solo sigue estos pasos:
              <br>-Disfruta cualquier producto o servicio de nuestros establecimientos afiliados.
              <br>-Antes de pagar, presenta tu documento de identidad como titular o beneficiario de la suscripción.
              <br>-¡Listo! El establecimiento hará efectiva la promoción.
              <br><br>Recuerda revisar los términos y condiciones legales de todas las promociones, ingresando a la ficha de la promoción en  <a target="_blank" href="https://clubelcomercio.pe">https://clubelcomercio.pe</a>  o descarga el App Club El Comercio en play store o app store.
            </li>
            <br>
            <li><b>Cambiar un beneficiario</b>
                <br>Si deseas cambiar un beneficiario, sigue estos pasos :
                <br>-Ingresa a  <a href="https://clubelcomercio.pe/beneficiario" target="_blank">https://clubelcomercio.pe/beneficiario</a>
                <br>-Iniciar sesión con tu correo y contraseña registrado.
                <br>-Selecciona el botón "Inscribe un beneficiario" y luego el botón "invitar".
                <br>-Completa el formulario con los datos del nuevo beneficiario.
                <br><br>Nota: Tu invitado, recibirá un correo y debe seguir los pasos detallados para la inscripción 😀, recuerda que tiene 24 horas para aceptar la invitación, caso contrario será necesario repetir el registro.
            </li>
            <!--
            <li><b>Agregar un beneficiario</b>
                <br>Si deseas agregar un beneficiario, sigue estos pasos :
                <br>-Ingresa al siguiente link: <a href="https://clubelcomercio.pe/beneficiario" target="_blank">https://clubelcomercio.pe/beneficiario</a>.
                <br>-Inicia sesión con tu correo y contraseña en la web del club, busca la opción de beneficario, selecciona invitar y completa los datos de tu invitado, le llegara un link de activación, le recordamos revisar la carpeta de spam.
            </li>-->
          </ol>          
            ¡Espero haberte ayudado! 😀
            <br>
        </p>
      </div>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('modal-xl') + showImg()"  style="padding: 7px;
    border-radius: 3px;
">Cerrar</b-button>
  </b-modal>


  </CRow>



</template>

<script>

  import axios from "axios";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  export default {
    name: "Breadcrumbs",
    data() {
      return {
        code: 0,
        isLoading: false,
        fullPage: true,
        firstName: localStorage.getItem("name").split(" ")[0],
        flagImg:1
      };
    },
    components: {
      Loading,
    },
    methods: {
      showModal() {
        // let element = this.$refs.modal.$el
        window.$("#exampleModalLong").modal('show')
      },
      hideModal(){
        window.$("#exampleModalLong").modal('hide')
      }
    },
    methods:{
      showImg(){
        this.flagImg=1;
      },
      hideImg(){
        this.flagImg=0;
        this.saveLog();
        let data ={
            "documentType":localStorage.getItem("tipoDocumento"),
            "documentNumber":localStorage.getItem("numeroDocumento"),
            "queryType":2,
            "subQueryType":5,
            "platformOrigin":"Landing"
          }
        console.log("SEN DATA TIPIFICACION =>",data);
        axios
        .post(
            `${process.env.VUE_APP_ROOT_API}/tipificacion`,
              data
            )
        .then((res)=>{
          console.log("Respuesta Tipificacion =>",res.data);
        })
        .catch((err)=>{
          console.log("Ocurrio un Error =>",err);
        })
      },
      saveLog(){
      let data ={
        "chanel":"Landing",
        "documentType":localStorage.getItem("tipoDocumento"),
        "documentNumber":localStorage.getItem("numeroDocumento"),
        "queryType":"Consulta",
        "actor":"Landing",
        "message":"Informativa Club"
        }
      axios
      .post(
            `${process.env.VUE_APP_ROOT_API}/log-conversaciones`,
              data
            )
      .then((res)=>{
        console.log("Log conversaciones =>",res);
      })
      .catch((err)=>{
      })
    }
    },
    mounted() {
      window.$(".close").hide();
      $("#pgb-container").hide();
    },

  };
</script>

